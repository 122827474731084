<template>
  <div class="component-community" :size="size">
    <div class="content">
      <img class="image" src="/assets/img/image.community.svg" alt="Chracter for community page" />
      <div class="email part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "이메일" : "Email" }}</div>
        <div v-if="store.state.site.lang === 'ko'">이메일을 통해 문의사항을 보내주시면 발신 메일로 답변드리겠습니다.</div>
        <div v-else>Send us your inquiry via e-mail and we will respond.</div>
        <div class="inner clearfix">
          <div class="half">
            <div class="form-group message">
              <label for="communityMessage">
                <span>{{ store.state.site.lang === "ko" ? "내용" : "Message" }}</span>
                <span class="color-point"> *</span>
                <span class="float-right">({{ state.community.message.length }} / 1000)</span>
              </label>
              <textarea class="form-control focus-border-pointer" id="communityMessage" placeholder="Hey! It's cool!" @input="state.community.message = $event.target.value" :disabled="state.loaded ? false : true"></textarea>
            </div>
          </div>
          <div class="half">
            <div class="name form-group">
              <label for="communityName">{{ store.state.site.lang === "ko" ? "이름" : "Name" }}</label>
              <input type="text" class="form-control focus-border-pointer" id="communityName" placeholder="James" v-model="state.community.name" @keyup.enter="send()" :disabled="state.loaded ? false : true" />
            </div>
            <div class="email form-group">
              <label for="communityEmailFrom">
                <span>{{ store.state.site.lang === "ko" ? "발신 메일" : "Sender Email" }} </span>
                <span class="color-point"> *</span>
              </label>
              <input type="email" class="form-control focus-border-pointer" id="communityEmailFrom" placeholder="name@example.com" v-model="state.community.email" @keyup.enter="send()" :disabled="state.loaded ? false : true" />
            </div>
            <div class="email form-group">
              <label for="communityEmailTo">
                <span>{{ store.state.site.lang === "ko" ? "수신 메일" : "Recipient Email " }} </span>
              </label>
              <input type="email" class="form-control focus-border-pointer" id="communityEmailTo" placeholder="name@example.com" value="africalibrary21@gmail.com" readonly />
            </div>
            <div class="satisfaction form-group">
              <label for="communitySatisfaction">
                <span>{{ store.state.site.lang === "ko" ? "서비스 평가" : "Satisfaction" }}</span>
                <span class="color-point"> *</span>
                <span class="float-right">{{ state.community.comment }}</span>
              </label>
              <div class="icons row">
                <div class="col text-right">
                  <i class="fa fa-frown-o" :class="{ 'active color-point': state.community.point === '1', disabled: !state.loaded }" @click="point('1')"></i>
                </div>
                <div class="col text-center">
                  <i class="fa fa-meh-o" :class="{ 'active color-point': state.community.point === '2', disabled: !state.loaded }" @click="point('2')"></i>
                </div>
                <div class="col">
                  <i class="fa fa-smile-o" :class="{ 'active color-point': state.community.point === '3', disabled: !state.loaded }" @click="point('3')"></i>
                </div>
              </div>
            </div>
            <div class="action">
              <button class="btn btn-point" @click="send()" :disabled="state.loaded ? false : true">
                <span v-if="store.state.site.lang === 'ko'">{{ `발송 ${state.loaded ? "" : "중..."}` }}</span>
                <span v-else>{{ `Send${state.loaded ? "" : "ing..."}` }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="comment part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "코멘트" : "Comments" }}</div>
        <p>{{ store.state.site.lang === "ko" ? "프로그램에 대한 의견을 남겨주시면, 내용을 검토하여 프로그램을 적용하겠습니다." : "Leave a comment about the program, we will review and apply the program." }}</p>
        <Comment :size="size" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import commLib from "../libs/commonLib";
import httpLib from "../libs/httpLib";
import { useStore } from "vuex";
import Comment from "../components/Comment";
import defiens from "../defines.json";

export default {
  props: {
    callback: Function,
    load: Function,
    size: String,
  },
  components: { Comment },
  setup(props) {
    const store = useStore();
    const state = reactive({
      community: {
        name: "",
        email: "",
        message: "",
        point: "3",
        comment: computed(() => {
          if (state.community.point === "3") {
            return "It's Cool";
          } else if (state.community.point === "2") {
            return "Not bad";
          } else {
            return "Bad";
          }
        }),
      },

      loaded: true,
    });

    const lnbRef = ref(null);
    const nav = defiens.navs.find((n) => n.name === "community");

    const point = (point) => {
      if (!state.loaded) {
        return;
      }

      state.community.point = point;
    };

    const send = () => {
      if (!state.loaded) {
        return;
      }

      if (!state.community.message) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "내용을 입력해주세요." : "Please enter your message.");
        document.querySelector("#communityMessage").focus();
      } else if (!state.community.email) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소를 입력해주세요." : "Email address is required.");
        document.querySelector("#communityEmailFrom").focus();
      } else if (!commLib.isValidEmail(state.community.email)) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소가 올바르지 않습니다." : "The email address is invalid.");
        document.querySelector("#communityEmailFrom").focus();
      } else if (state.community.message.length > 1000) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "메시지는 1000자를 넘을 수 없습니다." : "Messages cannot exceed 1000 characters.");
      } else if (state.community.name && state.community.name.length > 50) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "이름은 50자를 넘을 수 없습니다." : "Name cannot exceed 50 characters");
      } else if (state.community.email.length > 100) {
        commLib.message.show("warning", store.state.site.lang === "ko" ? "이메일 주소는 100자를 넘을 수 없습니다." : "E-mail addresses cannot exceed 100 characters.");
      } else if (confirm(store.state.site.lang === "ko" ? "이메일을 발송하시겠습니까?" : "Would you like to send an email?")) {
        state.loaded = false;

        httpLib
          .post("/api/email", state.community)
          .then(() => {
            commLib.message.show("success", store.state.site.lang === "ko" ? "이메일을 발송하였습니다." : "Email has been sent");
            state.loaded = true;
            state.community.name = "";
            state.community.email = "";
            state.community.message = "";
            state.community.point = "3";
            document.querySelector("#communityMessage").value = "";
          })
          .catch(() => {
            commLib.message.show("error", store.state.site.lang === "ko" ? "오류가 있습니다." : "There is an error.");
            state.loaded = true;
          });
      }
    };

    if (store.state.account.login) {
      state.community.email = store.state.account.email;
    }

    onMounted(() => {
      if (typeof props.load === "function") {
        props.load(lnbRef.value);
      }
    });

    return { store, state, lnbRef, nav, point, send };
  },
};
</script>
<style lang="scss" scoped>
.component-community {
  .part {
    &.comment {
      p {
        margin-bottom: 20px;
      }
    }

    .inner {
      margin: 0 -15px;

      .half {
        float: left;
        width: 50%;
        padding: 0 15px;
      }
    }

    &.email {
      > .inner {
        margin-top: 30px;

        > div {
          .message {
            textarea {
              border-radius: 0;
              height: 427px;
              width: 100%;
              resize: none;
              padding: 15px;
              box-shadow: none;
              font-size: 14px;
            }
          }

          input {
            font-size: 14px;
          }

          .email {
            margin: 20px 0;
          }

          .satisfaction {
            .icons {
              margin-top: 15px;

              i {
                cursor: pointer;
                padding: 5px;
                font-size: 42px;
                opacity: 0.25;

                &:hover {
                  opacity: 0.5;
                }

                &.active {
                  opacity: 1;
                }
              }
            }
          }

          label {
            display: block;
            font-size: 14px;
          }

          input {
            border-radius: 0;
            height: 50px;
            box-shadow: none;
          }

          .action {
            padding-top: 5px;

            button {
              width: 100%;
              border-radius: 0;
              height: 50px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  &[size="xs"],
  &[size="xxs"] {
    .inner {
      margin: 0 -15px;

      .half {
        float: none;
        width: 100%;
      }
    }

    .part.email > .inner > div {
      .name {
        margin: 19px 0 20px 0;
      }

      .message textarea {
        height: 100px;
      }
    }
  }
}
</style>
